<!-- Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted. -->
<template>
  <section class="tiny padded main">
    <h1><translate translate-context="*/*/*/Noun">Share your feedback</translate></h1>
    <form @submit.prevent="sendFeedback">
      <p><translate translate-context="*/*/*">Have you encountered an issue while using our products? Do you want to share your enhancements and features ideas?</translate></p>
      <p><translate translate-context="*/*/*">Use this page to send us your feedback and we'll get back to you. Simply tell us in your own words what didn't work, or the change you'd like to see.</translate></p>
      <div class="success message" v-if="success">
        <p>
          <translate translate-context="*/*/Form.Message">
            Your feedback was successfully sent, thank you!
          </translate>
        </p>
      </div>
      <div class="required field">
        <label for="message">
          <translate translate-context="*/Field/Label">Your message</translate>
        </label>
        <textarea
          autofocus
          required
          name="message"
          id="message"
          rows="10"
          :placeholder="placeholder"
          v-model="message"></textarea>
      </div>
      <app-button :is-loading="isLoading" type="submit">
        <translate translate-context="*/Button/Verb">Submit</translate>
      </app-button>
    </form>
    
    
  </section>
</template>     
<script>
import * as Sentry from "@sentry/browser";


export default {
  data () {
    return {
      message: "",
      isLoading: false,
      success: false,
    }
  },
  computed: {
    placeholder () {
      return this.$pgettext("*/*/*", "I miss some information on the users dashboard, is it possible to include it?")
    }
  },
  methods: {
    async sendFeedback () {
      this.isLoading = true
      this.success = false
      await Sentry.captureMessage(
        `[User Feedback] ${this.message}`,
        {
          tags: {type: "feedback"},
          user: this.$store.state.user,
          contexts: {
            feedback: {text: this.message}
          }
        }
      )
      this.message = ""
      this.isLoading = false
      this.success = true
    }
  }
}
</script>